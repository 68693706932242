import React, { useEffect, useState } from "react";
import {
  DashboardLayout,
  DeletePopupModal,
  PopupModal,
  FormParser,
} from "../Components";
import { NavLink, useNavigate ,useSearchParams} from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import DataTable from "react-data-table-component";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { Button, Modal } from "react-bootstrap";
import Masters from "../Components/masters.json";
import { BiSearch } from "react-icons/bi";
import { NavbarComponent } from "../Components/navbar/NavComponent";
import useDebouncedValue from "../Utils/Debounce";
const PaymentGateway = () => {
  const MastersModal = Masters.PaymentGateway;
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearch = searchParams.get("search") || "";
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [deletePopupType, setDeletepopupType] = useState("");
  const [loading, setLoading] = useState(false);
  const [RowId, SetRowId] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(30);
  const [popupType, setpopupType] = useState("");
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add New Item");
  const [message, setMessage] = useState("");
  const handleClose = () => {
    setShow(false);
    SetError("");
    setpopupType("");
  };
  const [Error, SetError] = useState("");
  const tableCustomStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        color: "#5C5C5C",
        justifyContent: "center",
        color: "rgb(33,37,41)",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "28px",
      },
    },
  };
  const handleShow = () => {
    setShow(true);
    setFormData({
      name: "",
      description: "",
      image: "",
      slug: "",
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      sort: "",
    });
    setModalTitle("Add New Item");
  };
  const [search, SetSearch] = useState({
    search:initialSearch,
  });
  const debouncedSearchTerm = useDebouncedValue(search.search, 500);
  const [Data, SetData] = useState([]);
  const GetData = async () => {
    setLoading(true);
    try {
      const responce = await axiosConfig.get(
        `catlog/categories/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`
      );
      SetData(responce.data.results);
      setLoading(false);
      setTotalRows(responce.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    SetSearch({ ...search, [name]: value });
    if(value){
      setSearchParams({ search: e.target.value });
    }
    else{
      setSearchParams();
    }
  };
  const handleSort = (column, direction) => {
    console.log(column.selector, direction);
    axiosConfig
      .get(
        `catlog/categories/?page=${currentPage}&page_size=${RowPerPage}&ordering=${
          direction === "asc" ? column.selector : -column.selector
        }`
      )
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentPage(1);
  };
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    slug: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    sort: "",
  });

  const catedit = (id) => {
    axiosConfig
      .get(`catlog/categories/${id}/`)
      .then((res) => {
        console.log(res);
        setFormData(res.data);
        setShow(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    GetData();
  }, [currentPage, RowPerPage, debouncedSearchTerm]);

  const formSubmit = (formData, setFieldErrors) => {
    console.log(formData);
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("slug", formData.slug);
    formDataToSend.append("sort", formData.sort);
    formDataToSend.append("meta_title", formData.meta_title);
    formDataToSend.append("meta_description", formData.meta_description);
    formDataToSend.append("meta_keyword", formData.meta_keyword);
    formDataToSend.append("image", formData.image.id);
    axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
    if (formData.id) {
      axiosConfig
        .patch(`catlog/categories/${formData.id}/`, formDataToSend)
        .then((res) => {
          console.log(res);
          setMessage("Category Updated Successfully");
          setpopupType("success");
          GetData();
          setShow(false);
          SetError("");
        })
        .catch((error) => {
          let errorData = {};
          console.log(error);
          if (
            error.response.data.error ===
            "A categories with this slug already exists."
          ) {
            errorData.error = "A categories with this slug already exists.";
            setFieldErrors((prev) => ({
              ...prev,
              ["slug"]: "A Category with this slug already exists.",
            }));
            //SetError("A categories with this slug already exists.")
            let error_message = error.response.data.error;
            Object.values(error_message) &&
              SetError(Object.values(error_message));
          }
          setpopupType("error");
        });
    } else {
      axiosConfig
        .post("catlog/categories/", formDataToSend)
        .then((res) => {
          console.log(res);
          setMessage("Category Created Successfully");
          setpopupType("success");
          GetData();
          setShow(false);
          SetError("");
        })
        .catch((error) => {
          let errorData = {};
          console.log(errorData);
          if (
            error.response.data.error ===
            "A categories with this slug already exists."
          ) {
            errorData.error = "A categories with this slug already exists.";
            setFieldErrors((prev) => ({
              ...prev,
              ["slug"]: "A Category with this slug already exists.",
            }));
            //SetError("A categories with this slug already exists.")
          }
          setpopupType("error");
          let error_message = error.response.data.error;
          console.log(error_message);
          Object.values(error_message) &&
            SetError(Object.values(error_message));
        });
    }
  };

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: row => row.name,
            sortable: true
        },
        {
            name: "image",
            selector: row => <>
                <img className="mng-prod-img" src={row.image && row.image.image} alt={row.image && row.image.image} />
            </>,
            sortable: true
        },
        {
            name: "slug",
            selector: "slug",
            cell: row => row.slug,
            sortable: true
        },
        {
            name: "sort",
            selector: "sort",
            cell: row => row.sort,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    catedit(row.id)
                    setModalTitle("Edit Item");


                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`catlog/categories/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Category revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`catlog/categories/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                return (
                    <>
                        {!row.is_suspended ?
                            <div className="row" >
                                <div className="col-4">
                                    <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>
                                <div className="col-4">
                                    <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                            </div>
                            :
                            <>
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <div className="child-sidenav">
                <NavbarComponent />
                <div className="childnav-content">
                    <div className="d-flex align-items-center">
                        <div className="col-4 input-cat-with-icon">
                            <span className="icon-product"><BiSearch /></span>
                            <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                        </div>
                        <div className="cat-button">
                            <div className="btn" onClick={handleShow} >
                                + Add New
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                </div>
            </div>
            <Modal
                size="xl"
                show={show}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} buttonTitle='Save' />
                </Modal.Body>
            </Modal>

      {popupType != "" && (
        <PopupModal
          setpopupType={setpopupType}
          popupType={popupType}
          Navigation=""
          error={Error}
          Message={message}
        />
      )}
      {deletePopupType != "" && (
        <DeletePopupModal
          setDeletepopupType={setDeletepopupType}
          deletePopupType={deletePopupType}
        />
      )}
    </>
  );
};
export { PaymentGateway };
