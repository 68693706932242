import React, { useEffect, useState } from "react";
import axiosConfig from "../Service/axiosConfig";
import { DashboardLayout, PopupModal } from "../Components";
import { BsFillCloudUploadFill } from "react-icons/bs";
import Select from "react-select";
import { useNavigate, useParams } from "react-router";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { FileManager } from "../Components/FileManager";
import { NavbarComponent } from "../Components/navbar/NavComponent";
import { ReactQuillComponent } from "../Components/FormParser/reactquill/reactquill";

const ProductForm = () => {
  let { id } = useParams();
  const [catid, setcatid] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [popupType, setpopupType] = useState("");
  const [message, setMessage] = useState("");
  const [Error, SetError] = useState("");
  const [loading, setLoading] = useState(false);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [existingSlug, setExistingSlug] = useState(null);
  const [slugManual, setSlugManual] = useState(false);
  const [TaxOptions, setTaxOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    price: 0,
    min_qty: "",
    max_qty: "",
    offer_price: "",
    weight: 0,
    measurement_unit: "",
    free_delivery: "",
    slug: "",
    subcategory: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    track_inventry: false,
    stock: 0,
    images: [],
    site: 3,
    brand: "",
    tax_classification: "",
    youtube_video_id: "",
    image_key: [],
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "options") {
      try {
        // We only try to parse if the value is a valid JSON string
        const parsedValue = JSON.parse(value); 
        setFormData({ ...formData, [name]: parsedValue });
      } catch (err) {
        // If it's not valid JSON, treat it as a string
        setFormData({ ...formData, [name]: value });
      }
    } else {
      if (name === "slug") {
        setSlugManual(true);
      }
      if (name === "name" && !existingSlug && !slugManual) {
        const auto_slug = value;
        const trimmedName = auto_slug.trim();
        const slug = trimmedName.replace(/[\/\s,&]+/g, "-").toLowerCase();
        setFormData({ ...formData, [name]: value, slug: slug });
      } else {
        setFormData({ ...formData, [name]: value });
      }
      if (name === "track_inventry") {
        setFormData({
          ...formData,
          track_inventry: formData.track_inventry === false ? true : false,
        });
      }
    }
  };

  const handleFormChangesubcategorie = (selectedOption, name) => {
    setFormData({
      ...formData,
      [name]: {
        value: selectedOption.value,
        category: selectedOption.category,
      },
    });
  };
  const HandleDropdownChange = (selectedOption, name) => {
    setFormData({
      ...formData,
      [name]: {
        value: selectedOption.value,
        label: selectedOption.label,
      },
    });
  };
  const HandleselectChange = (selectedOption, name) => {
    console.log(selectedOption, name);
    setFormData({
      ...formData,
      [name]: selectedOption?.value,
    });
  };
  const formSubmit = (event) => {
    console.log(formData, "inside formSubmit");
    let data = [];
    event.preventDefault();
    const formDataToSend = new FormData();
    if (formData.name) {
      formDataToSend.append("name", formData.name);
    }
    if (formData.description) {
      formDataToSend.append("description", formData.description);
    }
    if (formData.price) {
      formDataToSend.append("price", formData.price);
    }
    if (formData.min_qty) {
      formDataToSend.append("min_qty", formData.min_qty);
    }
    if (formData.tax_classification) {
      formDataToSend.append("tax_classification", formData.tax_classification);
    }
    if (formData.max_qty) {
      formDataToSend.append("max_qty", formData.max_qty);
    }
    if (formData.offer_price !== undefined && formData.offer_price !== null) {
      if (formData.offer_price === "") {
        formDataToSend.append("offer_price", 0);
      } else {
        formDataToSend.append("offer_price", formData.offer_price);
      }
    }
    if (formData.weight) {
      formDataToSend.append("weight", formData.weight);
    }

    if (formData.measurement_unit) {
      formDataToSend.append("measurement_unit", formData.measurement_unit);
    }
    if (formData.slug) {
      formDataToSend.append("slug", formData.slug);
    }
    if (formData.youtube_video_id) {
      formDataToSend.append("youtube_video_id", formData.youtube_video_id);
    }
    // formDataToSend.append("slug", formData.slug);
    // formData.subcategory && formData.subcategory.forEach((res, idx) => {
    //     formDataToSend.append("subcategory", res);
    //   });
    formData.subcategory.forEach((value, index) => {
      formDataToSend.append(`subcategory`, value);
    });
    formDataToSend.append("meta_title", formData.meta_title);
    formDataToSend.append("meta_description", formData.meta_description);
    formDataToSend.append("meta_keyword", formData.meta_keyword);
    if (formData.brand) {
      console.log(formData.brand, "brand");
      // Check if formData.brand is an object with an id property
      if (typeof formData.brand === "object" && formData.brand.id) {
        console.log(typeof formData.brand);
        formDataToSend.append("brand", formData.brand.id);
      } else {
        formDataToSend.append("brand", formData.brand);
      }
    }
    if (formData.image_key && formData.image_key.length > 0) {
      let data = formData.image_key.map((image) => image.id);
      formDataToSend.append("images", JSON.stringify(data));
    }
    if (formData.track_inventry) {
      formDataToSend.append("track_inventry", formData.track_inventry);
      formDataToSend.append("stock", formData.stock);
    }
    if (formData.category) {
      formDataToSend.append("category", formData.category);
    }
    if (formData.options) {
      formDataToSend.append("options", JSON.stringify(formData.options));
    }
    if (formData.size_chart && typeof(formData.size_chart) == 'number') {
      formDataToSend.append("size_chart", formData.size_chart);
    }
    

    axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
    if (id) {
      console.log(formDataToSend, "inside form submit");
      axiosConfig
        .patch(`catlog/products/${id}/`, formDataToSend)
        .then((res) => {
          console.log(res);
          setMessage("Product Updated Successfully");
          setpopupType("success");
          SetError("");
        })

        .catch((error) => {
          let errorData = {};
          if (error.response.data.error) {
            SetError(error.response.data.error);
          }
          if (error.response.data?.measurement_unit?.[0]) {
            SetError("measurement_unit is required");
          }
          if (error.response.data?.price?.[0]) {
            SetError("Price must be a non-empty number.");
          }
          if (error.response.data?.weight?.[0]) {
            SetError("weight must be a non-empty number.");
          }
          if (error.response.data?.images?.[0]) {
            SetError("images must be a non-empty .");
          }
          setpopupType("error");
        });
    } else {
      axiosConfig
        .post(`catlog/products/`, formDataToSend)
        .then((res) => {
          console.log(res);
          setMessage("Product Created Successfully");
          setpopupType("success");
          SetError("");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.error) {
            SetError(error.response.data.error);
          }
          if (error.response.data?.measurement_unit?.[0]) {
            SetError("measurement_unit is required");
          }
          if (error.response.data?.price?.[0]) {
            SetError("Price must be a non-empty number.");
          }
          if (error.response.data?.weight?.[0]) {
            SetError("weight must be a non-empty number.");
          }
          if (error.response.data?.images?.[0]) {
            SetError("images must be a non-empty .");
          }
          setpopupType("error");
        });
    }
  };
  const catedit = () => {
    axiosConfig
      .get(`catlog/products/${id}/`)
      .then((res) => {
        setExistingSlug(res.data.slug);
        HandleImageProduct(res.data);
        setSelectedImage(res.data.size_chart?.image)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const convertImageURLToBlob = async (imageURL, fileName) => {
    const response = await fetch(imageURL);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  };

  const HandleImageProduct = async (product) => {
    console.log(product);
    try {
      const res = await axiosConfig.get(
        `catlog/product-images/?product=${product.id}&default_image=`
      );
      console.log(res);
      if (res.data.results.length > 0) {
        res.data.results.forEach((images) => {
          const imageId = images.image.id;
          const imageUrl = images.image.image;
          setFormData((prevFormData) => {
            const newData = [...prevFormData.images, imageId];
            const newEditData = [
              ...prevFormData.image_key,
              { id: imageId, image: imageUrl },
            ];

            return {
              ...product,
              images: newData,
              image_key: newEditData,
              brand: product.brand && product.brand ? product.brand : "", // Update brand to just the id
              tax_classification: product.tax_classification
                ? product.tax_classification.id
                : "",
            };
          });
        });
      } else {
        setFormData({
          ...product,
          image_key: product.image ? [product.image] : "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [Branddata, setBranddata] = useState([]);
  async function fetchAllData() {
    const response = await axiosConfig.get(
      `/catlog/brands/?page_size=1000&is_suspended=false`
    );
    setBranddata(response.data.results);
  }

  useEffect(() => {
    if (id) {
      catedit();
    }
    const fetchCategories = async () => {
      const response = await axiosConfig.get(
        `catlog/sub-categories/?page_size=300&is_suspended=false`
      );
      setSubcategoryOptions(response.data.results);
    };
    fetchCategories();
    fetchTax();
    fetchAllData();
  }, []);
  const handleMultiSelectChange = (selectedOption, value, name) => {
    let data = [];
    selectedOption.forEach((res) => {
      data.push(res.value);
    });
    setFormData({ ...formData, [name]: data });
  };
  const handleSelectChange = (selectedOption, name) => {
    setFormData({ ...formData, [name]: selectedOption.value });
  };
  const handledefaultSlug = () => {
    if (!existingSlug && !formData.slug && !slugManual) {
      const name = formData.name;
      const trimmedName = name.trim();
      let slug = trimmedName
        .replace(/\s+/g, "")
        .replace(/\//g, "-")
        .replace(/,/g, "-")
        .toLowerCase()
        .split("/")
        .join("");

      // If the slug is within the allowed length, update the formData state
      setFormData({ ...formData, slug: slug });
    }
  };

  const removeImageByIndex = (indexToRemove) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image_key: prevFormData.image_key.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };
  let navigate = useNavigate();
  const HandleNavigate = () => {
    navigate(-1);
  };
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const Handlefilemanager = () => {
    setModalShow(true);
  };

  const Handlefilemanager2 = () => {
    setModalShow2(true);
  };

  const handleImageChange = (files) => {
    console.log(files)
    const file = files[0].image;
    const file_key = files[0].id;
    setSelectedImage(file);
    setFormData({ ...formData, ["size_chart"]: file_key });
};

  const handleImageDragStart = (e, index) => {
    e.dataTransfer.setData("imageIndex", index);
  };

  const fetchTax = async () => {
    const response = await axiosConfig.get(
      `masters/taxclasses/?page_size=1000&is_suspended=false`
    );
    console.log("All allTax:", response.data.results);
    setTaxOptions(response.data.results);
  };

  const handleImageDrop = (e) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData("imageIndex");
    const destinationIndex = 0; // Assuming you want to set the dragged image as the main image
    const imagesCopy = [...formData.image_key];

    // Swap the images
    const temp = imagesCopy[destinationIndex];
    imagesCopy[destinationIndex] = imagesCopy[sourceIndex];
    imagesCopy[sourceIndex] = temp;

    setFormData({
      ...formData,
      image_key: imagesCopy,
    });
  };
  console.log(formData);
  return (
    <div className="child-sidenav">
      <NavbarComponent />
      <div className="childnav-content">
        <form onSubmit={(e) => formSubmit(e, null)}>
          <div className="dashboard-header">
            <h5 className="dashboard-subtitle">
              {id ? "Edit Item" : "Add Item"}
            </h5>
            <div className="mx-4">
              <button
                type="submit"
                className="btn btn btn-white"
                onClick={HandleNavigate}
              >
                Cancel
              </button>
            </div>
            <div>
              <button type="submit" className="btn btn btn-primary">
                Submit
              </button>
            </div>
          </div>
          {Error && (
            <div className="Loginerror">
              <p>{Error}</p>
            </div>
          )}
          <div className="row align-items-center mt-2">
            <div className="col-12">
              <div className="flex-container">
                {formData.image_key && formData.image_key.length > 0 && (
                  <div className="image-field">
                    <div
                      onDrop={(e) => handleImageDrop(e)}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <img
                        className="image-item"
                        src={
                          formData.image_key[0] && formData.image_key[0].image
                            ? formData.image_key[0].image
                            : ""
                        }
                        alt={
                          formData.image_key[0] && formData.image_key[0].image
                            ? formData.image_key[0].image
                            : "images"
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="">
                  {formData.image_key && formData.image_key.length > 0 && (
                    <div className="image-grid">
                      {formData.image_key.map((image, index) => {
                        // Add a null check for 'image' property
                        const imageUrl =
                          image && image.image ? image.image : "";

                        return (
                          <div
                            className="flex-item-image"
                            key={index}
                            draggable
                            onDragStart={(e) => handleImageDragStart(e, index)}
                          >
                            <div>
                              <div className="image-container">
                                <img
                                  src={imageUrl}
                                  alt={`Image ${index + 1}`}
                                  className="grid-item"
                                />
                                <RxCross2
                                  onClick={() => removeImageByIndex(index)}
                                  className="cross-icon"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div>
                    <div
                      className="upload-multiple"
                      onClick={Handlefilemanager}
                    >
                      <div className="upload-plus">
                        <label htmlFor="imageInput1">
                          <AiOutlinePlus className="upload-width" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="form-field">
                <label htmlFor="name">Name:</label>
                <sup className="sup-colour">*</sup>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={formData.name}
                  onChange={(e) => handleFormChange(e)}
                  required
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-field">
                {console.log(subcategoryOptions.length)}
                <label htmlFor="subcategory">Subcategory:</label>{" "}
                <sup className="sup-colour">*</sup>
                <Select
                  isMulti
                  options={subcategoryOptions.map((res) => ({
                    value: res.id,
                    label: res.name,
                  }))}
                  value={subcategoryOptions
                    .filter(
                      (res) =>
                        Array.isArray(formData.subcategory) &&
                        formData.subcategory.includes(res.id)
                    )
                    .map((res) => ({ value: res.id, label: res.name }))
                    .filter(
                      (option, index, self) =>
                        self.findIndex((o) => o.value === option.value) ===
                        index
                    )}
                  onChange={(selectedOption) =>
                    handleMultiSelectChange(
                      selectedOption,
                      selectedOption.value,
                      "subcategory"
                    )
                  }
                  placeholder="Select Subcategories"
                  required
                />
              </div>
            </div>
            <div className="col-4" onClick={handledefaultSlug}>
              <div className="form-field">
                <label htmlFor="slug">Slug:</label>
                <sup className="sup-colour">*</sup>
                <input
                  type="text"
                  id="slug"
                  name="slug"
                  className="form-control"
                  value={formData.slug}
                  maxLength={50}
                  onChange={(e) => handleFormChange(e)}
                  required
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-field">
                <label htmlFor="price">Price:</label>
                <sup className="sup-colour">*</sup>
                <input
                  type="text"
                  id="price"
                  name="price"
                  className="form-control"
                  value={formData.price}
                  onChange={(e) => handleFormChange(e)}
                  required
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-field">
                <label htmlFor="offer_price">offer_price:</label>
                <input
                  type="text"
                  id="offer_price"
                  name="offer_price"
                  className="form-control"
                  value={formData.offer_price}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-field">
                <label htmlFor="weight">weight:</label>
                <sup className="sup-colour">*</sup>
                <div className="flexitem">
                  <input
                    type="text"
                    id="weight"
                    name="weight"
                    className="input-control"
                    value={formData.weight}
                    onChange={(e) => handleFormChange(e)}
                    required
                  />
                  <div className="input-group-append">
                    <select
                      name="measurement_unit"
                      id="measurement_unit"
                      className="input-group-text kg-tag"
                      value={formData.measurement_unit}
                      onChange={(e) => handleFormChange(e)}
                      required
                    >
                      <option value="">Select a unit</option>
                      <option value="kg">Kilograms</option>
                      <option value="g">Grams</option>
                      <option value="mg">Milligrams</option>
                      <option value="l">Liters</option>
                      <option value="ml">Milliliters</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-field">
                <label htmlFor="min_qty">min_qty:</label>
                <input
                  type="text"
                  id="min_qty"
                  name="min_qty"
                  className="form-control"
                  value={formData.min_qty}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-field">
                <label htmlFor="max_qty">max_qty:</label>
                <input
                  type="text"
                  id="max_qty"
                  name="max_qty"
                  className="form-control"
                  value={formData.max_qty}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-field">
                <label htmlFor="description">meta_title:</label>
                <input
                  type="text"
                  id="meta_title"
                  name="meta_title"
                  className="form-control"
                  value={formData.meta_title}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-field">
                <label htmlFor="description">meta_description:</label>
                <textarea
                  type="text"
                  rows="3"
                  id="meta_description"
                  name="meta_description"
                  className="form-control"
                  value={formData.meta_description}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="form-group col-4">
              <label className="form-label">Tax:</label>
              <Select
                options={TaxOptions.map((res) => ({
                  value: res.id,
                  label: res.title,
                }))}
                placeholder="Tax"
                value={TaxOptions.map((res) => ({
                  value: res.id,
                  label: res.title,
                })).find((res) => res.value === formData.tax_classification)}
                onChange={(selectedOption) => {
                  HandleselectChange(selectedOption, "tax_classification");
                }}
                isClearable
              />
            </div>
            <div className="form-group col-4">
              <label className="form-label">Brand:</label>
              <Select
                options={Branddata.map((res) => ({
                  value: res.id,
                  label: res.brand_name,
                }))}
                placeholder="brand"
                value={Branddata.map((res) => ({
                  value: res.id,
                  label: res.brand_name,
                })).find((res) => res.value === formData.brand?.id)}
                onChange={(selectedOption) =>
                  HandleselectChange(selectedOption, "brand")
                }
                isClearable
              />
            </div>
            <div className="form-group col-4">
              <div className="form-field">
                <label htmlFor="description">Youtube VideoID:</label>
                <input
                  type="text"
                  id="youtube_video_id"
                  name="youtube_video_id"
                  className="form-control"
                  value={formData.youtube_video_id}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>

            {/* <div className="row">
                                        <div>
                                            <div className="form-field">
                                                <label htmlFor="free_delivery">free_delivery</label>
                                                <input
                                                    type="checkbox"
                                                    id="free_delivery"
                                                    name="free_delivery"
                                                    // className="form-control"
                                                    value={formData.free_delivery}
                                                    onChange={(e) => handleFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
            <div className="col-6">
              <div className="form-field">
                <label htmlFor="description">Product Options - paste here</label>
                <textarea
                  type="text"
                  id="options"
                  name="options"
                  rows="8"
                  className="form-control"
                  value={JSON.stringify(formData.options, null, 2)}
                  onChange={(e) => handleFormChange(e)}
                ></textarea>
              </div>
            </div>
            <div className="col-6">
            <div className="form-field">
            <label>Size Chart</label>
            <div className="image-field">
                    <label className="image-container" htmlFor="imageInput" onClick={Handlefilemanager2}>
                        {selectedImage ? (
                            <img

                                className="image"
                                src={selectedImage}
                                alt={formData.image_key}
                            />
                        ) : (
                            <label htmlFor="imageInput" className="image-icon">
                                <BsFillCloudUploadFill className="upload-icon" />
                                <p className="textfile">Choose a file</p>
                            </label>
                        )}
                    </label>
                </div>
                </div>
              </div>
            <div className="col-12">
              <div className="form-field">
                <label htmlFor="description">meta_keyword:</label>
                <textarea
                  type="text"
                  id="meta_keyword"
                  name="meta_keyword"
                  rows="3"
                  className="form-control"
                  value={formData.meta_keyword}
                  onChange={(e) => handleFormChange(e)}
                ></textarea>
              </div>
            </div>
            <div className="col-12">
              <div className="form-field">
                <label htmlFor="description">Description:</label>
                {console.log(formData, "formdata")}
                <ReactQuillComponent
                  SetData={setFormData}
                  Data={formData}
                  name="description"
                  value={formData.description}
                />
                {/* <textarea
                                    type="text"
                                    id="description"
                                    name="description"
                                    className="form-control"
                                    rows="6"
                                    value={formData.description}
                                    onChange={(e) => handleFormChange(e)}
                                ></textarea> */}
              </div>
            </div>
          </div>
          <div className="margin-inventry">
            <div className="display-head">
              <div>
                <h5 className="dashboard-subtitle-custom">Inventory</h5>
              </div>
            </div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                name="track_inventry"
                checked={formData.track_inventry}
                value="true"
                onChange={handleFormChange}
              />
              <label class="form-check-label" for="flexSwitchCheckDefault">
                Track inventory
              </label>
            </div>
            {formData.track_inventry === true && (
              <div className="row">
                <div className="col-4">
                  <div className="form-field">
                    <label htmlFor="inventory">Inventory:</label>
                    <input
                      type="text"
                      id="stock"
                      name="stock"
                      className="form-control"
                      value={formData.stock}
                      onChange={(e) => handleFormChange(e)}
                      required={formData.track_inventry}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
      {popupType != "" && (
        <PopupModal
          setpopupType={setpopupType}
          popupType={popupType}
          Message={message}
          Navigation={-1}
          error={Error}
        />
      )}
      {modalShow && (
        <FileManager
          modalShow={modalShow}
          setModalShow={setModalShow}
          setFormData={setFormData}
          formData={formData}
          ismultiple={true}
          value="image_key"
        />
      )}
      {modalShow2 && (
        <FileManager modalShow={modalShow2} setModalShow={setModalShow2} handleFile={handleImageChange} ismultiple={false} value="image_key" />
      )}
    </div>
  );
};
export { ProductForm };
